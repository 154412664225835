<template>
  <div class="vulnerabilityCenterAll">
    <div class="vulnerabilityCenterFirst">
      <div class="vulnerabilityCenterFirst1">
        <h1>御之安-漏洞管理平台</h1>
        <p>
          以风险为视角出发，审视
          IT、安全和合规状况，快速识别、研究漏洞，并进行优先级分析。
        </p>
        <p>
          兵法云：知己知彼百战不殆，基于御之安漏洞管理平台产品体系，可避免网络安全运营成本的过量投入，同时进一步提升安全管理运营的有效性，帮助企业以攻击视角评估自身可能存在的网络安全风险和脆弱性。
        </p>
      </div>
    </div>
    <div class="vulnerabilityCenterSecond">
      <img
        src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fs.secrss.com%2Fanquanneican%2F193988709846b43da119e3df1e8c71ad.jpg&refer=http%3A%2F%2Fs.secrss.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672543187&t=2e0d75a54b7850860bee2e646fd07b25"
        alt=""
      />
    </div>
    <div class="vulnerabilityCenterThird">
      <div class="vulnerabilityCenterThird1">
        <div class="vulnerabilityCenterThird1Left">
          <img src="../../../../assets/img/proctCenter/5.webp" alt="" />
        </div>
        <div class="vulnerabilityCenterThird1Right">
          <p>
            漏洞管理的本质是，对于随企业业务发展而不断产生的各类漏洞采用一系列措施进行从发现到解决闭环管理，以避免因漏洞被利用并演变为安全事件。
          </p>
        </div>
      </div>
      <p>
        传统漏洞管理的需求本质类似于项目管理中的风险管理模型，是以“由被动防御到主动防御”核心思想衍生，希望将安全事故应急模式由被动的“亡羊补牢”转变为“早发现、早预防、早处理”。
      </p>
    </div>
    <div class="codeAuditThird">
      <h1>积蓄新力量，开启新跨越</h1>
      <div class="codeAuditThird1">
        <ul>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">攻击面检测发现</p>
              <span class="codeAuditThird1-2-2">
                　漏洞扫描是攻击面检测的核心与基础。攻击面的主动检测更关注实战性、高效性，对近期公布1day漏洞和真正被用于实际攻击的漏洞进行真实性验证。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">攻击面分析研判</p>
              <span class="codeAuditThird1-2-2">
                网络安全的本质，是攻守双方人与人的较量。真实攻击者不会无目标、无差别进行攻击。攻击面管理，需要专注对抗这个本质进行分析和研判，需要评估漏洞的利用成本、漏洞所在资产的价值，该漏洞是否能构成实现最终目标的环节等等。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">攻击面情报预警</p>
              <span class="codeAuditThird1-2-2">
                攻击面检测与发现，主要是以聚合分析内部信息为主。然而在互联网海量信息的时代，外部情报的获取也不容忽视。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">攻击面响应处置</p>
              <span class="codeAuditThird1-2-2">
                针对攻击面进行全生命周期的监控和运营，利用管理手段缩小攻击面的安全风险影响。同时结合SOAR技术，对安全管理和应急流程进行贴合业务的自动化调度编排，将处置经验深度留存，解耦处置对人的强依赖。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .vulnerabilityCenterAll {
    width: 100%;
    .vulnerabilityCenterFirst {
      background-image: url(../../../../assets/img/proctCenter/5.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 140px 40px;
      .vulnerabilityCenterFirst1 {
        padding-bottom: 50px;
        h1 {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 30px;
          color: #fff;
          text-align: center;
        }
        p {
          text-indent: 1.5rem;
          font-size: 14px;
          font-weight: 200;
          line-height: 30px;
          color: #fff;
        }
      }
    }
    .vulnerabilityCenterSecond {
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;
      display: flex;
      justify-content: center;
      img {
        width: 72vw;
        height: 38vh;
      }
    }
    .vulnerabilityCenterThird {
      margin-top: 40px;
      background: #f1f5ff;
      padding: 30px;
      p {
        text-indent: 1.5rem;
        font-size: 14px;
        font-weight: 200;
        line-height: 30px;
        
      }
      .vulnerabilityCenterThird1 {
        display: flex;
        justify-content: center;
        align-items: center;
        .vulnerabilityCenterThird1Left {
          margin-right: 30px;
          img {
            width: 30vw;
            height: 26vh;
          }
        }
        .vulnerabilityCenterThird1Right {
          width: 40%;
          p {
            text-indent: 1.5rem;
            font-size: 14px;
            font-weight: 200;
            line-height: 30px;
            
          }
        }
      }
    }
    .codeAuditThird {
      width: 100%;
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;
      h1 {
        text-align: center;
      }
      .codeAuditThird11 {
        width: 220px;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        li {
          display: flex;
          padding: 20px;
          background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
          border-radius: 12px;
          margin-top: 20px;
          .codeAuditThird1-1 {
            img {
              width: 48px;
              height: 48px;
              margin-right: 20px;
            }
          }
          .codeAuditThird1-2 {
            .codeAuditThird1-2-1 {
              font-size: 24px;
              color: #1f2329;
            }
            .codeAuditThird1-2-2 {
              font-size: 16px;
              color: #51565d;
              margin-top: 20px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}
</style>